import React from "react";

const ColorPicker = ({
  colours,
  pattern,
  thumbnailColourOverride = null,
  setColourPreference,
}) => (
  <div
    className="color-picker"
    style={{
      background: (thumbnailColourOverride || colours[0]).replace(
        /rgba\((\d+), (\d+), (\d+), [\d.]+\)/,
        "rgba($1, $2, $3, 1)"
      ),
      backgroundImage: pattern,
    }}
    onClick={() => setColourPreference(colours, pattern)}
  >
    {/* colour */}
  </div>
);

export default ColorPicker;
