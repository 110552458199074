// src/PlayerControlsModal.js
import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const PlayerControlsModal = ({ isOpen, onRequestClose, onKick, onChangeTeam, player, modalStyle, amILeader }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                overlay: { backgroundColor: 'transparent' },
                content: { ...modalStyle, padding: "6px", width: "250px", color: "var(--background-color)", background: "#FFF", border: '1px solid var(--background-color)', borderRadius: 0 },
            }}
            contentLabel="Player Controls"
        >
            <h3 style={{ marginTop: "0px", marginBottom: "6px" }}>{player.username}</h3>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "column" }}>
                <button 
                    disabled={!amILeader()} 
                    style={{ 
                        opacity: amILeader() ? "100%" : "50%", 
                        color: "var(--text-on-bg-color)", 
                        border: "none", 
                        borderRadius: "2px", 
                        backgroundColor: "var(--background-color-full-tp)", 
                        width: '100%', 
                        height: "35px", 
                        fontSize: "1.3em" 
                    }} 
                    onClick={() => onKick(player)}
                >
                    Kick
                </button>
                <button 
                    disabled={!amILeader()} 
                    style={{ 
                        opacity: amILeader() ? "100%" : "50%", 
                        color: "var(--text-on-bg-color)", 
                        border: "none", 
                        borderRadius: "2px", 
                        backgroundColor: "var(--background-color-full-tp)", 
                        width: '100%', 
                        height: "35px", 
                        fontSize: "1.3em",
                        marginTop: "4px"
                    }} 
                    onClick={() => onChangeTeam(player)}
                >
                    Move team
                </button>
            </div>
        </Modal>
    );
};

export default PlayerControlsModal;
