import React, { Component } from "react";
import Axios from "axios";
import io from "socket.io-client";

const baseUrl = process.env.REACT_APP_OUTLAW_API_URL || "http://localhost:9000"; // "https://outlaw.squeaker.dev"

const Home = () => {
  let socket;

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const createLobby = () => {
    // console.log("here");
    Axios.get(`${baseUrl}/api/createLobby`, { withCredentials: true })
      .then(function (res) {
        // console.log("ermmm");
        // console.log(res);
        window.location.href = `/${res.data.code}`;
      })
      .catch(function (err) {
        console.log("error in creating namespace", err);
      });
    // console.log("sent request");
  };

  return (
    <div className="lobby-container">
      <div className="lobby">
        <form onSubmit={handleSubmit}>
          <div className="form-content">
            <h1 style={{ margin: 0 }}>Home</h1>

            <h3>Welcome to Outlaw!</h3>
            <p>
              Outlaw is a team-based word game. You'll have a word to describe
              -- without saying any of the 5 outlawed words.
              <br /> <br />
              Click below to start a game.
            </p>
          </div>
          <button id="create-game" onClick={createLobby}>
            Create Lobby
          </button>
        </form>
      </div>
    </div>
  );
};

export default Home;
