import React from "react";

const BanneredDiv = ({
  cls,
  header,
  content,
  headerStyle = {},
  contentStyle = {},
  after = null,
}) => (
  <div className={cls}>
    <div className="banner" style={headerStyle}>
      <h3 style={{ margin: "0em" }}>{header}</h3>
    </div>
    <div className="banner-content" style={contentStyle}>
      {content}
    </div>
    {after}
  </div>
);

export default BanneredDiv;
