import React, { useEffect, useState } from "react";

const Timer = ({ initialTime, start, onFinish, socket }) => {
  const [time, setTime] = useState(initialTime);
  const radius = 28; // Three-quarters of the original radius
  const circumference = 2 * Math.PI * radius;
  const [circleDashoffset, setCircleDashoffset] = useState(circumference);

  useEffect(() => {
    setTime(initialTime); // Reset the timer when initialTime changes
  }, [initialTime]);

  // useEffect(() => {
  //   if (start && time >= 0) {
  //     const intervalId = setInterval(() => {
  //       setTime((prevTime) => prevTime - 1);
  //     }, 1000);

  //     return () => clearInterval(intervalId);
  //   }
  // }, [start, time]);

  useEffect(() => {
    if (time === 0 && onFinish) {
      onFinish();
    }
  }, [time, onFinish]);

  useEffect(() => {
    const progress = (time / initialTime) * circumference;
    setCircleDashoffset(-(circumference - progress));
  }, [time, initialTime, circumference]);

  useEffect(() => {
    if (socket) {
      const countdownListener = (val) => {
        setTime((time) => val);
      };

      socket.on("setTimer", countdownListener);

      return () => {
        socket.off("setTimer", countdownListener);
      };
    }
  }, [socket]);

  return (
    <div style={{ position: "relative", display: "inline-block", width: "75px", height: "75px" }}>
      <svg width="75" height="75">
        <circle
          cx="37.5"
          cy="37.5"
          r={radius}
          stroke="white"
          fill="none"
          strokeWidth="3.75" // Three-quarters of the original stroke width
          style={{ 
            strokeDasharray: circumference, 
            strokeDashoffset: circleDashoffset, 
            transition: "stroke-dashoffset 1s linear", 
            transform: "rotate(-90deg)", 
            transformOrigin: "50% 50%" 
          }}
        />
      </svg>
      <div style={{ position: "absolute", top: "49%", left: "24%", transform: "translate(-50%, -50%)" }}>
        <h2 style={{ margin: 0 }}>{time >= 0 ? time : 0}</h2>
      </div>
    </div>
  );
};

export default Timer;
