import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeaf, faDroplet, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import confetti from "canvas-confetti";


const WinningMessage = ({ teams, username }) => {
  const confettiSettings = {
    spread: 360,
    ticks: 50,
    gravity: 0,
    decay: 0.94,
    startVelocity: 30,
    colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
  };

  function shootStars() {
    confetti({
      ...confettiSettings,
      particleCount: 40,
      scalar: 1.2,
      shapes: ["star"],
    });

    confetti({
      ...confettiSettings,
      particleCount: 10,
      scalar: 0.75,
      shapes: ["circle"],
    });
  }
  const isWinningTeam = (team) => {
    return team.players.map((p) => p.username).includes(username);
  };

  useEffect(() => {
    if (
      (teams[0].score > teams[1].score && isWinningTeam(teams[0])) ||
      (teams[1].score > teams[0].score && isWinningTeam(teams[1]))
    ) {
      shootStars();
    } else {
    }
  }, [teams, username]);

  return (
    <>
      <h4 style={{ marginBottom: "0" }}>
        <span className="leaf">
          <FontAwesomeIcon icon={faLeaf} style={{ marginRight: "8px" }} />
          {teams[0].name}
        </span>{" "}
        - {teams[0].score} pts
      </h4>
      <h4 style={{ marginTop: "0" }}>
        <span className="water">
          <FontAwesomeIcon icon={faDroplet} style={{ marginRight: "8px" }} />
          {teams[1].name}
        </span>{" "}
        - {teams[1].score} pts
      </h4>
      <h2 style={{ color: "rgba(255, 202, 0, 1)" }}>
        <FontAwesomeIcon icon={faTrophy} />{" "}
        {teams[0].score > teams[1].score
          ? `${teams[0].name} wins!`
          : teams[1].score > teams[0].score
          ? `${teams[1].name} wins!`
          : "It's a draw!"}
      </h2>
    </>
  );
};

export default WinningMessage;
