import React, { useEffect, useRef, useState, memo } from "react";

const ChatBox = memo(({ addChatMessage, chatMessages, username }) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputValue.trim() !== "") {
        addChatMessage(inputValue, username);
        setInputValue("");
        event.target.focus();
        inputRef.current.focus(); // Keep the input field focused
      }
    }
  };

  useEffect(() => {
    inputRef.current.focus(); // Focus the input field when the component mounts
  }, []);

  return (
    <>
      <div className="chat-box">
        <div>
          {chatMessages.map((message, index) => {
            if (message.systemType === "red") {
              return (
                <div className="chat-message system-message-red" key={index}>
                  <strong>{message.content}</strong>
                </div>
              );
            } else if (message.systemType === "green") {
              return (
                <div className="chat-message system-message-green" key={index}>
                  <strong>{message.content}</strong>
                </div>
              );
            } else if (message.systemType === "blue") {
              return (
                <div className="chat-message system-message-blue" key={index}>
                  <strong>{message.content}</strong>
                </div>
              );
            } else {
              return (
                <div className="chat-message" key={index}>
                  <strong style={{ color: message.color }}>
                    {message.author}
                  </strong>{" "}
                  {message.content}
                </div>
              );
            }
          })}
        </div>
      </div>
      <input
        type="text"
        placeholder="Type a message..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        ref={inputRef} // Attach the ref to the input element
      />
    </>
  );
});

export default ChatBox;
